
import { IonButtons, IonContent, IonMenuButton, IonPage } from '@ionic/vue';
import axios from "axios";
import { jsonDataURL, loadAds } from '@uinterview/uinterview'

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonMenuButton,
		IonPage,
	},
	data() {
		return {
			componentKey: 0,
			article: [],
			ent: [],
			otherNews: [],
			otherNews2: [],
			jsonResp: [],
			commentList: '',
		};
	},
	methods:{
		shareArticle(article){
			const shareOptions = {
				message: article.title,
				subject: article.title,
				files: [article.image_url], // an array of filenames either locally or remotely
				url: article.item_link,
				chooserTitle: 'Share With', // Android only, you can override the default share sheet title
				iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
			};
			const shareOnSuccess = function(result) {
				console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
				console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
			};
			const shareOnError = function(msg) {
				console.log("Sharing failed with message: " + msg);
			};
			window['plugins'].socialsharing.shareWithOptions(shareOptions, shareOnSuccess, shareOnError);
		},
		getComments(){
			this.$refs.commentsButton.style.display = 'none'
			this.$refs.blogCommentForm.style.display = 'block'
			this.$refs.commentsContainer.innerHTML = '<span class="loader"><img src="/assets/images/reload-circle-outline.svg">Loading comments...</span>'
			axios.get(`https://uinterview.com/messenger/get_comments.php?id=${this.$refs.commentsButton.getAttribute("postid")}&time=${new Date().getTime()}`)
				.then(response => {
					this.commentList = response.data
				})
		},
		putComment(){
			this.$refs.formMsg.innerHTML = ""
			this.$refs.formSuccessMsg.innerHTML = ""
			const commentAuthor = this.$refs.commentAuthor.value
			const commentAuthorEmail = this.$refs.commentAuthorEmail.value
			const commentText = this.$refs.commentText.value
			let continue1 = false
			let continue2 = false
			let continue3 = false
			if(commentAuthor == '') {
				this.$refs.commentAuthorMsg.innerHTML = "Please input your name"
			} else {
				this.$refs.commentAuthorMsg.innerHTML = ""
				continue1 = true
			}
			const re = /\S+@\S+\.\S+/;
			const emailValidForm = re.test(this.$refs.commentAuthorEmail.value)
			if( (commentAuthorEmail == '') || (emailValidForm == false) ) {
				this.$refs.commentAuthorEmailMsg.innerHTML = "Please input your email"
			} else {
				this.$refs.commentAuthorEmailMsg.innerHTML = ""
				continue2 = true
			}
			if(commentText == '') {
				this.$refs.commentTextMsg.innerHTML = "Please input your message"
			} else {
				this.$refs.commentTextMsg.innerHTML = ""
				continue3 = true
			}
			if( (continue1 == true) && (continue2 == true) && (continue3 == true) ) {
				const bodyFormData = new FormData();
				bodyFormData.append('comment_post_ID', this.$refs.commentsButton.getAttribute("postid"));
				bodyFormData.append('author', commentAuthor);
				bodyFormData.append('email', commentAuthorEmail);
				bodyFormData.append('comment', commentText);
				bodyFormData.append('url', '');
				this.$refs.submitBtn.value = "Loading..."
				axios.post('https://uinterview.com/messenger/put_comment.php', bodyFormData)
					.then((response) => {
						const commentHTML = response.data
						const newComment = document.createElement("LI")
						newComment.innerHTML = commentHTML
						document.getElementsByClassName('comment-list')[0].appendChild(newComment)
						this.$refs.submitBtn.value = "Send"
						this.$refs.commentAuthor.value = ""
						this.$refs.commentAuthorEmail.value = ""
						this.$refs.commentText.value = ""
						this.$refs.formSuccessMsg.innerHTML = "Comment added successfully!"
					})
					.catch(err => {
						this.$refs.formMsg.innerHTML = err.response.data
						this.$refs.submitBtn.value = "Send"
					});
			}
		}
	},
	mounted() {
		loadAds()
		const d = new Date
		this.$refs.copyYear.innerHTML = d.getFullYear()
		if (localStorage.appData) {
			const dateNow = Date.now()
			const jsonExpire = (JSON.parse(localStorage.appData).expires)*1000
			if(dateNow <= jsonExpire) {
				this.entriesList = JSON.parse(localStorage.appData)
				this.article = this.entriesList.news[this.$route.params.id]
				this.otherNews = Object.values(this.entriesList.news).slice(0,6)
				this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
			} else {
				axios.get(jsonDataURL)
					.then(response => {
						this.entriesList = response.data
						localStorage.setItem('appData', JSON.stringify(response.data));
						this.article = this.entriesList.news[this.$route.params.id]
						this.otherNews = Object.values(this.entriesList.news).slice(0,6)
						this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
					})
			}
		} else {
			axios.get(jsonDataURL)
				.then(response => {
					this.entriesList = response.data
					localStorage.setItem('appData', JSON.stringify(response.data));
					this.article = this.entriesList.news[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.news).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
				})
		}
	},
	watch: {
		'$route' (to, from) {
			if(to.path == '/') {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
						})
				}
			} else if(to.path.includes('/category/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
					this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
					this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
							this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
							this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
						})
				}
			} else if(to.path.includes('/page/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.pages[this.$route.params.id]
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.pages[this.$route.params.id]
						})
				}
			} else if(to.path.includes('/videos/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.videos[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.videos[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
						})
				}
			} else {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.news[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.news).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.news[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.news).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
						})
				}
			}
			this.$el.querySelector("#container").scrollIntoView({behavior: 'smooth'});
			this.$refs.commentsButton.style.display = 'block'
			this.$refs.blogCommentForm.style.display = 'none'
			this.$refs.commentsContainer.innerHTML = ''
		}
	},
};
